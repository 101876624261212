import React,{useEffect,useState} from 'react';
import { getAirportDetail,neatbyAirport } from '../../api/apiCall';
import { Link, useParams } from 'react-router-dom';
import FlightSvg from  "../../svg/flightIcon";
import BlogDetailLoader from  '../../components/BlogDetailLoader';

const AirportDetail = () =>{
    const [blogDetail, setBlogDetail] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [nearByAirport, setNearByAirport] = useState([]);
    // const [nearByLoading, setNearByLoading] = useState(false);

    const { blog_slug } = useParams();
    const fetchData = async () => {
        setLoading(true)
        const result = await getAirportDetail({"slug":blog_slug})
        setBlogDetail(result.data)
        setLoading(false)
        // setNearByLoading(true)
        const nearByResult = await neatbyAirport({"slug":blog_slug})
        setNearByAirport(nearByResult.error ? [] : nearByResult.data.airportListObj)
        // setNearByLoading(false)

        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    };
    useEffect(()=>{
        document.title = "Airport detail | Know Rules";
        fetchData()
    },[blog_slug]);
    return(
        <div className="detail-blog-sec pt-100 pb-50">
            <div className="container">
            {
                !isLoading && blogDetail?.getAirportDetails?.id ?
                <div className='row'>
                    <div className='col-lg-8'>
                        <div className='head mb-10'>
                            <h2>{blogDetail.getAirportDetails.name_of_the_airport}</h2>
                            <div className="sc-title-two fst-italic position-relative">
                                {/* {blogDetail.getAirportDetails.title} */}
                                {blogDetail.getAirportDetails.cityName},{blogDetail.getAirportDetails.stateName},{blogDetail.getAirportDetails.countryName}
                            </div>
                        </div>
                        <div dangerouslySetInnerHTML={{__html:blogDetail.getAirportDetails.description}}/>
                    </div>
                    <div className='col-lg-4'>
                        <div className='related-guideline'>
                            <h2>NEAREST AIRPORTS</h2>
                            {
                                nearByAirport.map((item)=>(
                                    <div className='col-lg-12 related-guide-card'  style={{textAlign: "left"}} key={item.id}>
                                        <Link to={`/airport/${item.slug}`} >
                                            <span><FlightSvg/>{"  "}{item.name_of_the_airport}-{item.cityName},{item.stateName},{item.countryName}</span>
                                        </Link>
                                    </div> 
                                ))
                            }
                        </div>
                    </div>
                </div>
                    :
                    <div className="container">
                        <BlogDetailLoader/>
                    </div>

                }
            </div>
        </div>
    );
};
export default AirportDetail;
