import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import MainMenu from './mainMenu';
import ScrollToTop from "../ScrollToTop";
const Header = ()=> {
    const [navbar, setNavbar] = useState(false);

    const changeBackground = () => {
        if (window.scrollY >= 10) {
          setNavbar(true);
        } else {
          setNavbar(false);
        }
      };

    useEffect(() => {
        window.addEventListener("scroll", changeBackground);
        return () => {
          window.removeEventListener("scroll", changeBackground);
        };
      }, []);
    return(
        <header className={`theme-main-menu sticky-menu theme-menu-eight border-bottom ${ navbar ? "fixed" : "fixed"}`}>
            <div className="inner-content position-relative container">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="logo ">
                      <Link to={'/'} className="d-block">
                          <img  src="/assets/images/logo-know.png"  alt="logo" width={95} height={70} loading="lazy"/>
                      </Link>
                  </div>
                  {/* <SearchInput/> */}
                  <MainMenu />
                </div>
            </div>
            <ScrollToTop/>
        </header>
    );
};

export default Header;