import axios from "./config";
import API_ENDPOINTS from "./apiCollection";


export const subscribe = async(payload) =>{
    const response = await axios.post(API_ENDPOINTS['SUBSCRIBE_EMAIL_SUBMIT_API'], payload);
        if (response.data) {
            return response.data;
        } else {
            return [];
        }
}
export const getCarouselImagesListObj = async (payload) => {
    try {
        const response = await axios.post(API_ENDPOINTS['GALLERY_IMAGES_LIST_API'], payload);
        if (response.data) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        console.log(error);
        return [];
    }
};

export const getTravelGuidePageBlogList = async (payload) => {
    try {
        const response = await axios.get(API_ENDPOINTS['GET_ALL_BLOGLIST_API']+payload);
        if (response.data) {
            return response.data.data;
        } else {
            return {};
        }
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const getHomePageGuideLinestypeBlogList = async (payload) => {
    try {
        const response = await axios.post(API_ENDPOINTS['GET_ALL_GUIDELINELIST_API'],payload);
        if (response.data) {
            return response.data.data[0];
        } else {
            return {};
        }
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const getHomePageBlogSlider = async () => {
    try {
        const response = await axios.get(API_ENDPOINTS['GET_HOME_SLIDER_BLOG_API']);
        if (response.data?.data?.getBlogsObj?.length>0) {
           const res = response.data.data.getBlogsObj.map(item=>({...item,imageUrl : response.data.data.imagePath+item.fullimage}))
            return res;
        } else {
            return [];
        }
    } catch (error) {
        console.log(error);
        return [];
    }
};

export const searchAirport = async (payload) => {
    try {
        const response = await axios.get(API_ENDPOINTS['SEARCH_AIRPORT_NAME_API']+"?"+ payload,);
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
};
export const neatbyAirport = async (payload) => {
    try {
        const response = await axios.post(API_ENDPOINTS['GET_AIRPORT_LIST_API'],payload,);
        return response.data;
    } catch (error) {
        console.log(error);
        return [];
    }
};


export const getDetailedBlog = async (payload) => {
    try {
        const response = await axios.post(API_ENDPOINTS['GET_BLOG_DETAILS_API'],payload);
        return response.data
    } catch (error) {
        console.log(error);
        return [];
    }
};

export const getDetailedGuidline = async (payload) => {
    try {
        const response = await axios.post(API_ENDPOINTS['GET_GUIDELINE_DETAILS_API'],payload);
        return response.data
    } catch (error) {
        console.log(error);
        return [];
    }
};
export const getDRelatedGuidlines = async (payload) => {
    try {
        const response = await axios.post(API_ENDPOINTS['GET_RELATED_GUIDELINES_API'],payload);
        return response.data
    } catch (error) {
        console.log(error);
        return [];
    }
};

export const searchAirportGuideLine = async(payload)=> {
    try {
        const response = await axios.post(API_ENDPOINTS['SEARCH_AIRPORT_GUIDELINES_API'],payload);
        return response.data
    } catch (error) {
        console.log(error);
        return [];
    }
}
export const getAirportDetail = async(payload)=> {
    try {
        const response = await axios.post(API_ENDPOINTS['GET_AIRPORT_DETAILS_API'],payload);
        return response.data
    } catch (error) {
        console.log(error);
        return [];
    }
}

export const getPageContent = async(payload)=> {
    try {
        const response = await axios.post(API_ENDPOINTS['GET_PAGE_CONTENT_API'],payload);
        return response.data
    } catch (error) {
        console.log(error);
        return [];
    }
}
export const queryFormSubmit = async(payload)=> {
    try {
        const response = await axios.post(API_ENDPOINTS['CONTACT_FORM_SUBMIT_API'],payload);
        return response.data
    } catch (error) {
        console.log(error);
        return [];
    }
}